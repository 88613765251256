<template>
  <div @click.stop.prevent class="flex-align gap-2" :title="title || text">
    <div
      class="border rounded-lg highlight text-3 text-truncate px-2 py-1 rounded-lg"
      style="font-family: Menlo, Consolas, monospace"
    >
      {{ text }}
    </div>
    <CopyBtn :text="value || text" size="25" variant="flat" border />
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "TextCopy",
  props: {
    text: { type: String, required: true },
    value: { type: String },
    title: { type: String },
  },
});
</script>
